<template>
  <div>
    <main class="page-content">
      <div class="account-page-area">
      <div class="">
          <div class="row">
            <div class="col-lg-3 account_border-right">
              <sideMenuVendor></sideMenuVendor>
            </div>

            <div class="col-lg-9">
              <div>
                <div>
                  <h4 class="comm-title">Add Vehicles</h4>
                </div>
                <div
                  class="card card-container registerFormwrap"
                  style="margin: 0px"
                >
                  <div class="form-row">
                    <div class="form-group col">
                      <label>Brand</label>
                      <div class="custdorpicon" style="position: relative">
                        <input
                          :class="customclsbrand"
                          v-model="st.vehmake"
                          type="text"
                          @change="clearAllFields"
                          placeholder="Enter brand name"
                        />
                        <div v-if="filterByBrand" class="vehSelection">
                          <div
                            v-for="(car, index) in filterByBrand"
                            :key="index"
                          >
                            <span
                              v-if="st.vehmake != car.make && st.vehmake != ''"
                              @click="getModelList(car.make)"
                              >{{ car.make }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <span class="errorMessageCls" v-if="v$.st.vehmake.$error">
                        {{ v$.st.vehmake.$errors[0].$message }}
                      </span>
                      <!-- <label class="errorMessageCls" v-if="makeMessage">{{
                        makeMessage
                      }}</label> -->
                    </div>
                    <div class="form-group col">
                      <label>Model Name</label>
                      <div class="custdorpicon" style="position: relative">
                        <input
                          :class="customclsmodel"
                          v-model="st.vehmodel"
                          type="text"
                          @change="clearVarientFields"
                          placeholder="Enter model name"
                        />
                        <div v-if="filterByModel" class="vehSelection">
                          <div
                            v-for="(car, index) in filterByModel"
                            :key="index"
                          >
                            <span
                              v-if="
                                st.vehmodel != car.model && st.vehmodel != ''
                              "
                              @click="getVariantList(car.model)"
                              >{{ car.model }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.vehmodel.$error"
                      >
                        {{ v$.st.vehmodel.$errors[0].$message }}
                      </span>
                    </div>
                    <div class="form-group col">
                      <label>Variant</label>
                      <select :class="customclsvar" v-model="st.vehVariant">
                        <option value="0" disabled>Select variant name</option>
                        <option
                          v-for="(st, index) in VechVariant"
                          :key="index"
                          :value="st.variant"
                        >
                          <span @change="getAvgPrice">
                            {{ st.variant }}
                          </span>
                        </option>
                      </select>
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.vehVariant.$error"
                      >
                        {{ v$.st.vehVariant.$errors[0].$message }}
                      </span>
                    </div>
                    <div class="form-group col">
                      <label>Vehicle Type</label>
                      <select :class="customclsvar" v-model="st.veType">
                        <option value="0" disabled>Select vehicle type</option>
                        <option
                          v-for="(st, index) in VechType"
                          :key="index"
                          :value="st.vehTypeId"
                        >
                          {{ st.vehTypeName }}
                        </option>
                      </select>
                      <span class="errorMessageCls" v-if="v$.st.veType.$error">
                        {{ v$.st.veType.$errors[0].$message }}
                      </span>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label>Vin Number</label>
                      <input
                        :class="customclsvar"
                        v-model="st.vinNumber"
                        placeholder="Enter VIN number"
                      />
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.vinNumber.$error"
                      >
                        {{ v$.st.vinNumber.$errors[0].$message }}
                      </span>
                    </div>
                    <div class="form-group col">
                      <label>Km Clocked</label>
                      <input
                        type="number"
                        @change="savekm($event)"
                        :class="customclsvar"
                        v-model="st.kmClocked"
                        placeholder="Enter KM clocked "
                      />
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.kmClocked.$error"
                      >
                        {{ v$.st.kmClocked.$errors[0].$message }}
                      </span>
                    </div>
                    <div class="form-group col">
                      <label>Year of Make</label>
                      <input
                        type="number"
                        @change="saveYOM($event)"
                        :class="customclsvar"
                        v-model="st.yearofmake"
                        placeholder="Enter year of make "
                      />
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.yearofmake.$error"
                      >
                        {{ v$.st.yearofmake.$errors[0].$message }}
                      </span>
                    </div>

                    <div class="form-group col">
                      <div :tooltip="'Avg price : ' + vehAvgPrice">
                        <label>Vehicle Price</label>
                        <input
                          :class="customclsvar"
                          v-model="st.vehPrice"
                          type="number"
                          placeholder="Enter price"
                        />
                        <span
                          class="errorMessageCls"
                          v-if="v$.st.vehPrice.$error"
                        >
                          {{ v$.st.vehPrice.$errors[0].$message }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label> State</label>
                      <select
                        :class="customclsvar"
                        v-model="st.selectedState"
                        @change="getDistrictbyLocId(st.selectedState)"
                      >
                        <option value="0" disabled>Select state</option>
                        <option
                          v-for="(st, index) in States"
                          :key="index"
                          :value="st.state"
                        >
                          <span>
                            {{ st.state }}
                          </span>
                        </option>
                      </select>
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.selectedState.$error"
                      >
                        {{ v$.st.selectedState.$errors[0].$message }}
                      </span>
                    </div>
                    <div class="form-group col">
                      <label> District</label>
                      <select
                        :class="customclsvar"
                        v-model="st.selectedDistrict"
                        @change="getLocationByStateId(st.selectedDistrict)"
                      >
                        <option value="0" disabled>Select district</option>
                        <option
                          v-for="(st, index) in Districts"
                          :key="index"
                          :value="st.district"
                        >
                          <span>
                            {{ st.district }}
                          </span>
                        </option>
                      </select>
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.selectedDistrict.$error"
                      >
                        {{ v$.st.selectedDistrict.$errors[0].$message }}
                      </span>
                    </div>
                    <div class="form-group col">
                      <label> City</label>
                      <select :class="customclsvar" v-model="st.selectedCity">
                        <option value="0" disabled>Select city</option>
                        <option
                          v-for="(st, index) in City"
                          :key="index"
                          :value="st.locId"
                        >
                          <span>
                            {{ st.locName }}
                          </span>
                        </option>
                      </select>
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.selectedCity.$error"
                      >
                        {{ v$.st.selectedCity.$errors[0].$message }}
                      </span>
                    </div>

                    <div class="form-group col">
                      <div class="">
                        <label>Warranty</label>
                        <select
                          :class="customclsvar"
                          v-model="st.underWarantee"
                        >
                          <option value="0" disabled>Select warranty</option>
                          <option value="1">YES</option>
                          <option value="0">NO</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <label>Dealer note</label>
                      <textarea
                        name="vehdes"
                        :class="customclsvar"
                        v-model="st.description"
                        placeholder="Enter dealer note"
                      ></textarea>
                      <!-- <input type="text" :class="customclsvar" /> -->
                      <span
                        class="errorMessageCls"
                        v-if="v$.st.description.$error"
                      >
                        {{ v$.st.description.$errors[0].$message }}
                      </span>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group col">
                      <div>
                        <div class="imageupload">
                          <h2 style="font-size: 15px">Select an image</h2>
                          <input multiple type="file" @change="onFileChange" />
                        </div>
                        <div>
                          <a
                            @click="removeAllImage"
                            class="addV-clear"
                            style="cursor: pointer"
                            >Clear All Images</a
                          >
                        </div>
                        <div
                          v-if="images"
                          style="display: flex; flex-wrap: wrap"
                        >
                          <div
                            v-for="(image, index) in images"
                            :key="index"
                            style="position: relative"
                          >
                            <img
                              :src="image"
                              style="
                                width: 170px;
                                height: 120px;
                                object-fit: cover;
                              "
                            />
                            <a @click="removeImage(index)" class="update-v-dlt">
                              <i class="fad fa-trash-alt"></i>
                            </a>
                          </div>
                        </div>
                        <span class="errorMessageCls" v-if="imagesmessage">
                          {{ imagesmessage }}
                        </span>
                      </div>
                    </div>
                  </div>

                  <button @click="submitForm" class="addV-btn">Submit</button>
                  <div>
                    <!-- <span v-if="message">
                      {{ message }}
                    </span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import userService from "@/services/user.service";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
// import { Form, Field } from "vee-validate";
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import * as Yup from "yup";

export default {
  name: "Add Vehicles",
  components: {
    // Form,
    // Field,
    // FieldArray,
    sideMenuVendor,
  },
  data() {
    const schema = Yup.object().shape({
      vehType: Yup.string().required("Select a Type "),
      vinNumber: Yup.string().required("Vin numer is required"),
      kmClocked: Yup.string().required("Kilometer is required"),
      vehPrice: Yup.string().required("Vehicle price is required"),
      yearofmake: Yup.string().required("Year of make is required"),
      warrenty: Yup.string().required("Warrenty is required"),
      state: Yup.string().required("State is required"),
      district: Yup.string().required("District is required"),
      city: Yup.string().required("City is required"),
      description: Yup.string().required("Description is required"),
    });

    return {
      successful: false,
      loading: false,
      message: "",
      imagesmessage: "",
      schema,
      userinfo: [],
      VechBrand: [],
      VechModel: [],
      VechType: [],
      States: [],
      Districts: [],
      City: [],
      imagesArry: [],
      images: [],
      brandName: "",
      modelName: "",
      variantName: "",
      typeName: "",
      variant: "",
      vinNum: "",
      km: "",
      yom: "",
      vehAvgPrice: 0,
      VechVariant: [],
      makeMessage: "",
      modelMessage: "",
      variantMessage: "",
      customclsvar: "form-control",
      customclsmodel: "form-control",
      customclsbrand: "form-control",
      v$: useValidate(),
      st: {
        vehmake: "",
        vehmodel: "",
        vehVariant: "",
        veType: "",
        vinNumber: "",
        kmClocked: "",
        yearofmake: "",
        vehPrice: "",
        selectedState: "",
        selectedDistrict: "",
        selectedCity: "",
        description: "",
        underWarantee: "",
      },
    };
  },

  validations() {
    //  const mustBeLearnVue = (value) => !value ;
    // mustBeLearnVue: helpers.withMessage("Please Select a Brand", mustBeLearnVue),
    return {
      st: {
        vehmake: { required },
        vehmodel: { required },
        vehVariant: { required },
        veType: { required },
        vinNumber: { required },
        kmClocked: { required },
        yearofmake: { required },
        vehPrice: { required },
        selectedState: { required },
        selectedDistrict: { required },
        selectedCity: { required },
        description: { required },
        underWarantee: { required },
      },
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    filterByBrand() {
      return this.VechBrand.filter((item) => {
        if (item.make != null && this.st.vehmake) {
          return item.make
            .toLowerCase()
            .includes(this.st.vehmake.toLowerCase());
        }
      });
    },
    filterByModel() {
      return this.VechModel.filter((item) => {
        if (item.model != null && this.st.vehmodel) {
          return item.model
            .toLowerCase()
            .includes(this.st.vehmodel.toLowerCase());
        }
      });
    },
  },
  mounted() {
    // if (this.loggedIn) {
    //   this.$router.push("/vendorprofile");
    // }
    this.getVehicleBrand();
    this.getVehicleTypes();
    this.getAllStates();
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));

      if (typeof this.userinfo.sellerId != "undefined") {
        this.getVehicleBrand();
        this.getVehicleTypes();
        this.getAllStates();
      }
    }
  },
  methods: {
    submitForm() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.$error) {
        // if ANY fail validation
        // if (this.images.length <= 0) {
        //   this.imagesmessage = "Add atleast one image";
        // } else if (this.images.length > 14) {
        //   this.imagesmessage = "Can't upload more than 15 image";
        // } else {
        //alert("Somthing went wrong.");
        // }
      } else {
        if (this.images.length < 3) {
          this.imagesmessage = "Add atleast 4 image";
        } else if (this.images.length > 15) {
          this.imagesmessage = "Can't upload more than 15 image";
        } else {
          userService
            .addVeh(this.userinfo.sellerId, this.v$.st, this.images)
            .then((response) => {
              this.currentuserinfo = response.data.Data[0];
              this.message = response.data.Message;

              this.$toast.success("Saved suceesfully", {
              position: "top",
              duration: 3000,
            });

            setTimeout(() => {
              location.reload();
            }, 3000);
             
            })
            .catch((e) => {
              // console.log(e.response);
              this.message = e.response.data.Message;
              this.$toast.error("!Oops sothing went wrong. try again", {
              position: "top",
              duration: 3000,
            });
            });
        }

        //alert("Form failed validation" );
      }
    },
    clearAllFields() {
      if (this.st.vehmake == "" || this.st.vehmake == null) {
        this.st.vehmodel = "";
        this.st.vehVariant = "";
        this.getAvgPrice();
      }
    },
    clearVarientFields() {
      if (this.st.vehmodel == "" || this.st.vehmodel == null) {
        this.st.vehVariant = "";
        this.getAvgPrice();
      }
    },
    getModelList(make) {
      this.st.vehmake = make;
      this.getVehicleModel(make);
      this.getAvgPrice();
    },
    getVariantList(model) {
      this.st.vehmodel = model;
      this.getVehicleVariant(model);
      this.getAvgPrice();
    },
    // getVariantFinalList(variant) {
    //   this.st.vehVariant = variant;
    // },

    savekm(e) {
      this.km = e.target.value;
      this.getAvgPrice();
    },
    saveYOM(e) {
      this.yom = e.target.value;
      this.getAvgPrice();
    },

    getAvgPrice() {
      if (
        this.st.vehmake &&
        this.st.vehmodel &&
        this.st.vehVariant &&
        this.km &&
        this.yom
      ) {
        userService
          .getAvgPrice(
            this.st.vehmake,
            this.st.vehmodel,
            this.km,
            this.yom,
            this.st.vehVariant
          )
          .then((response) => {
            if (response.data.Data[0].avgVehiclePrice) {
              this.vehAvgPrice = response.data.Data[0].avgVehiclePrice;
            } else {
              this.vehAvgPrice = "Price is not available";
              // Math.floor(Math.random() * 1000000 + 600000) + "  Random Price";
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files);
    },
    createImage(files) {
      var vm = this;
      for (var index = 0; index < files.length; index++) {
        var reader = new FileReader();
        reader.onload = function (event) {
          const imageUrl = event.target.result;
          vm.images.push(imageUrl);
          vm.imagesmessage = "";
        };
        reader.readAsDataURL(files[index]);
      }
    },
    removeImage(index) {
      this.images.splice(index, 1);
    },

    onSubmit(user) {
      if (this.images.length <= 0) {
        this.imagesmessage = "Add atleast one image";
      } else if (this.images.length > 14) {
        this.imagesmessage = "Can't upload more than 15 image";
      } else if (!this.st.vehmake) {
        this.customclsbrand = "form-cont-error";
        this.customclsmodel = "form-cont-error ";
        this.customclsvar = "form-cont-error ";
        this.makeMessage = "Please Select Brand";
      } else if (!this.st.vehmodel) {
        this.makeMessage = "";
        this.customclsbrand = "form-control";
        this.customclsmodel = "form-cont-error ";
        this.customclsvar = "form-cont-error ";
        this.modelMessage = "Please Select Model";
      } else if (!this.st.vehVariant) {
        this.modelMessage = "";
        this.customclsbrand = "form-control";
        this.customclsmodel = "form-control";
        this.customclsvar = "form-cont-error ";
        this.variantMessage = "Please Select Variant";
      } else {
        this.imagesmessage = "";
        user.vehBrand = this.st.vehmake;
        user.vehModel = this.st.vehmodel;
        //  user.vehType = this.st.vehVariant;
        userService
          .addVeh(this.userinfo.sellerId, user, this.images)
          .then((response) => {
            this.currentuserinfo = response.data.Data[0];
            this.message = response.data.Message;
            location.reload();
          })
          .catch((e) => {
            // console.log(e.response);
            this.message = e.response.data.Message;
          });
      }
    },
    removeAllImage() {
      this.images = [];
    },
    getVehicleBrand() {
      userService
        .getVehicleBrand()
        .then((response) => {
          this.VechBrand = response.data.Data;
          // console.log(this.VechBrand);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleModel(make) {
      userService
        .getVehicleModel(make)
        .then((response) => {
          this.VechModel = response.data.Data;
          // console.log(this.VechModel);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },

    getVehicleVariant(model) {
      userService
        .getVehicleVariant(model)
        .then((response) => {
          this.VechVariant = response.data.Data;
          // console.log(this.VechVariant);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getVehicleTypes() {
      userService
        .getVehicleTypes()
        .then((response) => {
          this.VechType = response.data.Data;
          // console.log(this.VechType);
        })
        .catch((e) => {
          console.log(e);
          // this.$router.push("/Auction").catch(() => {
          //   "details/auctionbyname";
          // });
        });
    },
    getAllStates() {
      let id = this.userinfo.sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getStatebyLocId()
          .then((response) => {
            this.States = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getDistrictbyLocId(event) {
      let id = this.userinfo.sellerId;
      let statename = event;
      console.log(statename);
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getDistrictbyLocId(statename)
          .then((response) => {
            this.Districts = response.data.Data;
            // console.log(JSON.stringify(response.data.Data));
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getLocationByStateId(event) {
      let id = this.userinfo.sellerId;
      let district = event;
      // console.log(event);
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getLocationByStateId(district)
          .then((response) => {
            this.City = response.data.Data;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    // goToLogin() {
    //   setTimeout(() => this.$router.push("/vendorLogin"), 5000);
    // },
  },
};
</script>
<style scoped>
.registerFormwrap label {
  margin-bottom: 2px;
}
img {
  width: 30%;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}
/* START TOOLTIP STYLES */
[tooltip] {
  position: relative; /* opinion 1 */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
  text-transform: none; /* opinion 2 */
  font-size: 0.9em; /* opinion 3 */
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[tooltip]::before {
  content: "";
  border: 5px solid transparent; /* opinion 4 */
  z-index: 1001; /* absurdity 1 */
}
[tooltip]::after {
  content: attr(tooltip); /* magic! */

  /* most of the rest of this is opinion */
  font-family: Helvetica, sans-serif;
  text-align: center;

  /*
    Let the content set the size of the tooltips
    but this will also keep them from being obnoxious
    */
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 0.3ch;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #333;
  color: #fff;
  z-index: 1000; /* absurdity 2 */
}

/* Make the tooltips respond to hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}

/* don't show empty tooltips */
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}

/* FLOW: UP */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-0.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-0.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(0.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(0.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}
.vehSelection {
  position: absolute;
  background-color: white;
  width: 100%;
  padding-left: 10px;
  z-index: 99999;
}
.errorMessageCls {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.form-cont-error {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ef2546;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .form-group.col {
    width: 50%;
    flex: 50%;
  }
}
.addV-btn {
  background-color: #002868;
  color: white;
  display: inline-block;
  width: 100px;
  padding: 5px;
  border-radius: 5px;
  align-self: end;
}
.addV-clear {
  background-color: #008ad5;
  padding: 5px 11px;
  border-radius: 5px;
  color: white !important;
  text-transform: capitalize;
  margin-top: 10px;
  display: inline-block;
  float: right;
}
</style>
